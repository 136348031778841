import api from '@services/api';
import { PaginationResponse } from '../models/pagination';
import { SharingPrevisionRequest, SharingPrevisionResponse } from '../models/sharingPrevision';

interface QueryInput {
  query: string;
  initPeriod: string;
  endPeriod: string;
  personSearchTerm: string;
  page: number;
  size: number;
}

/**
 * <p>
 * API endpoints that cover all Sharing Prevision related CRUD calls
 */
export const PredicationsSharingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    SharingPrevision: builder.query<PaginationResponse<SharingPrevisionResponse>, SharingPrevisionRequest>({
      query: (request) => ({
        url: encodeURI(
          `/sharing/sharing-prevision/search?initPeriod=${request.initPeriod}&endPeriod=${request.endPeriod}&personSearchTerm=${request.personSearchTerm}&page=${request.page}&size=${request.size}`,
        ),
        method: 'GET',
      }),
      providesTags: ['SharingPrevision'],
    }),
  }),
});

export const { useSharingPrevisionQuery } = PredicationsSharingApi;
