interface PermissionRole {
  [key: string]: Array<string>;
}

interface PageName {
  [key: string]: string;
}

/**
 * Since every page is accessed only if the user owns certain roles,
 * we must define a set of Pages and the roles of every page
 */

export const PAGE_NAMES: PageName = {
  COSTCENTER: 'COSTCENTER',
  SOLICITAÇÕES: 'SOLICITAÇÕES',
  PERIODO: 'PERIODO',
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
  PERMISSION_BY_USER: 'PERMISSION_BY_USER',
  PREVISION: 'PREVISION',
  DESTINATION: 'DESTINATION',
};

const PERMISSION_ROLES: PermissionRole = {
  COSTCENTER: ['PMO', 'MANAGER'],
  SOLICITAÇÕES: ['PMO', 'MANAGER'],
  PERIODO: ['PMO'],
  ADMIN: ['ADMIN'],
  SUPER: ['SUPER'],
  PERMISSION_BY_USER: ['ADMIN', 'SUPER'],
  PREVISION: ['PREVISION', 'ADM'],
  DESTINATION: ['ADMIN', 'PMO', 'MANAGER'],
};

export default PERMISSION_ROLES;
