import { ON_CHANGE_DELAY } from '@/app/constants/values';
import { useCostCentersSearchNameQuery } from '@/app/services/costcenterApi';
import { useAddResourceSharingMutation } from '@/app/services/resourceSharingApi';
import { CostSplit, Employee } from '@/components/costcenter/CostCenterDetailsTable';
import { CostCenter } from '@app/models/costcenter';
import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, Box, Button, InputProps, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'react-use';

import uuid from 'react-uuid';

type CreateResourceSharingProps = {
  employee?: Employee;
  costcenter?: CostCenter;
  closeDrawer: CallbackFunction;
  periodId: number;
  incrementLastTimestamp: CallbackFunction;
  editCostsplit?: CostSplit;
};

interface ResourceSharing extends Record<string, string | number | CostCenter | undefined> {
  id?: number;
  destinationCostCenter?: CostCenter;
  percent: number;
  observation?: string;
  supportCostCenter?: CostCenter;
  status?: string;
  key: string;
}

type OnChangeCallbackFunction = (event: React.ChangeEvent<HTMLInputElement>) => void;
interface SearchFieldProps {
  [key: string]: string | number | SearchFieldProps | OnChangeCallbackFunction | undefined;
}

type CallbackFunction = () => void;
type SetResourceSharingFunction = (index: number, property: string, value: CostCenter | number | string) => void;
type RemoveResourceSharingFunction = (index: number) => void;

function CostCenterSearch(props: {
  index: number;
  setResourceSharing: SetResourceSharingFunction;
  costCenter?: CostCenter;
  propertyName: string;
}) {
  const { index, setResourceSharing, costCenter, propertyName } = props;
  const [searchCostCenter, setSearchCostCenter] = useState(costCenter?.code ? costCenter?.code : '===');
  const throttledSearchCostCenter = useThrottle(searchCostCenter, ON_CHANGE_DELAY);
  const { data: costCentersByName } = useCostCentersSearchNameQuery({
    query: throttledSearchCostCenter,
    showClosed: false,
  });
  const { t } = useTranslation('resourceSharing');

  return (
    <Autocomplete
      freeSolo
      isOptionEqualToValue={(option, value) => option.code === value.code}
      style={{ width: '100%' }}
      size="small"
      defaultValue={costCenter}
      onChange={(event, newValue) => {
        if (newValue && (newValue as CostCenter).code) {
          setResourceSharing(index, propertyName, newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length >= 1) {
          setSearchCostCenter(newInputValue);
        }
      }}
      options={costCentersByName ? costCentersByName.content.map((option: CostCenter) => option) : []}
      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.code} - ${option.name}`)}
      renderOption={(params, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
          {option.code} {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            '& input': {
              padding: '0px',
            },
          }}
          ref={params.InputProps.ref}
          InputProps={
            {
              ...params.inputProps,
              style: {
                paddingRight: '10px',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '10px',
                fontSize: '13px',
                borderRadius: '0px',
              },
            } as InputProps
          }
          size="small"
          style={{ width: '100%', height: '32px', fontSize: '13px' }}
          autoFocus
          placeholder={
            t(propertyName === 'supportCostCenter' ? 'support.cost.center' : 'destination.cost.center') as string
          }
        />
      )}
    />
  );
}
function SearchField(props: SearchFieldProps) {
  return (
    <TextField
      sx={{
        '& input': {
          padding: '7px',
        },
      }}
      InputProps={{
        ...props,
        style: {
          paddingRight: '10px',
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingLeft: '10px',
          fontSize: '13px',
          borderRadius: '0px',
        },
      }}
      size="small"
      style={{
        marginTop: '1px',
        width: '100%',
        borderRadius: '0px',
      }}
    />
  );
}

function ResourceSharingCell(props: {
  index: number;
  sizeList: number;
  originCostcenter?: CostCenter;
  currentPercentage: number;
  removeResourceSharing: RemoveResourceSharingFunction;
  setResourceSharing: SetResourceSharingFunction;
  resourceSharing: ResourceSharing;
}) {
  const {
    index,
    originCostcenter,
    removeResourceSharing,
    resourceSharing,
    currentPercentage,
    setResourceSharing,
    sizeList,
  } = props;
  const { t } = useTranslation('resourceSharing');
  return (
    <div style={{ marginTop: '20px', display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <CostCenterSearch
          index={index}
          setResourceSharing={setResourceSharing}
          costCenter={resourceSharing.destinationCostCenter}
          propertyName="destinationCostCenter"
        />
        <SearchField
          inputProps={{
            max: currentPercentage + resourceSharing.percent,
            min: 0,
          }}
          type="number"
          placeholder={t('allocation.percent') as string}
          value={resourceSharing.percent}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setResourceSharing(index, 'percent', Number(event.target.value));
          }}
        />
        <SearchField
          placeholder={t('observation.optional') as string}
          value={resourceSharing.observation}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setResourceSharing(index, 'observation', event.target.value);
          }}
        />
        {originCostcenter?.branchCode &&
          resourceSharing?.destinationCostCenter?.branchCode &&
          originCostcenter?.branchCode !== resourceSharing.destinationCostCenter?.branchCode && (
            <div>
              <div
                style={{ marginTop: '4px', fontSize: '12px', color: '#4B4B4B', display: 'flex', alignItems: 'center' }}
              >
                <div style={{ marginRight: '8px', display: 'flex' }}>
                  <InfoIcon sx={{ color: '#4B4B4B', fontSize: 16 }} />
                </div>
                <div>{t('for.costsplits.between.different.regions.inform.the.support.cost.center')}</div>
              </div>
              <CostCenterSearch
                index={index}
                setResourceSharing={setResourceSharing}
                costCenter={resourceSharing.supportCostCenter}
                propertyName="supportCostCenter"
              />
            </div>
          )}
      </div>
      {sizeList > 1 && (
        <div
          className="close_resource_sharing"
          style={{ width: '32px' }}
          onClick={() => removeResourceSharing(index)}
          onKeyUp={() => removeResourceSharing(index)}
          role="button"
          tabIndex={0}
        >
          <img width="12px" height="12px" src="/ligth_close.svg" alt="close" />
        </div>
      )}
    </div>
  );
}

function AddResourceSharing(props: {
  totalPercent: number;
  originCostcenter?: CostCenter;
  edit: boolean;
  newResourceSharingList: Array<ResourceSharing>;
  removeResourceSharing: RemoveResourceSharingFunction;
  addResourceSharing: CallbackFunction;
  setResourceSharing: SetResourceSharingFunction;
}) {
  const {
    totalPercent,
    originCostcenter,
    edit,
    newResourceSharingList,
    removeResourceSharing,
    addResourceSharing,
    setResourceSharing,
  } = props;

  const { t } = useTranslation(['resourceSharing', 'costcenter']);
  const currentPercentage = totalPercent - newResourceSharingList.reduce((acc, current) => acc + current.percent, 0);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ overflow: 'auto', paddingRight: '8px' }}>
        {newResourceSharingList.map((resourceSharing, index) => (
          <>
            <ResourceSharingCell
              key={resourceSharing.key}
              index={index}
              originCostcenter={originCostcenter}
              currentPercentage={currentPercentage}
              resourceSharing={resourceSharing}
              removeResourceSharing={removeResourceSharing}
              setResourceSharing={setResourceSharing}
              sizeList={newResourceSharingList.length}
            />
            <Tooltip
              arrow
              enterDelay={200}
              leaveDelay={200}
              placement="left"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#F7F7F7',
                    minWidth: '10rem',
                    border: '1px solid #D9D9D9',
                    color: 'black',
                    '& .MuiTooltip-arrow': {
                      color: '#F7F7F7',
                      '&:before': {
                        border: '1px solid #D9D9D9',
                      },
                    },
                  },
                },
              }}
              title={
                <div>
                  <Typography sx={{ display: 'inline', fontSize: '13px' }}>
                    {t('Não é possível escolher um CR bloqueado como destino')}
                  </Typography>
                </div>
              }
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left' }}>
                {resourceSharing.destinationCostCenter?.blocked && (
                  <span style={{ color: 'red' }}>{t('costcenter:blocked')}</span>
                )}
              </Typography>
            </Tooltip>
          </>
        ))}
      </div>
      {!edit && (
        <div style={{ marginTop: '24px' }}>
          <div
            style={{ fontSize: '14px', color: '#0493C7', cursor: 'pointer' }}
            onClick={() => addResourceSharing()}
            onKeyUp={() => addResourceSharing}
            role="button"
            tabIndex={0}
          >
            {t('add.another.costsplit')}
          </div>
        </div>
      )}
    </div>
  );
}

function saveButtonDisabled(list: Array<ResourceSharing>, originCostcenter?: CostCenter) {
  return list.some((resourceSharing: ResourceSharing) => {
    const originBranch = originCostcenter?.branchCode;
    const destinationCostCenterBranch = resourceSharing.destinationCostCenter?.branchCode;

    if (!originBranch || !destinationCostCenterBranch) {
      return false;
    }

    return !(
      resourceSharing.destinationCostCenter?.code &&
      resourceSharing.percent > 0 &&
      ((originBranch && originBranch === destinationCostCenterBranch) || resourceSharing.supportCostCenter?.code) &&
      !resourceSharing.destinationCostCenter.blocked
    );
  });
}

export default function CreateResourceSharing(props: CreateResourceSharingProps) {
  const { t } = useTranslation(['resourceSharing', 'common']);
  const { employee, costcenter, closeDrawer, periodId, incrementLastTimestamp, editCostsplit } = props;
  const [newResourceSharingList, setNewResourceSharingList] = useState([
    editCostsplit
      ? {
          key: uuid(),
          destinationCostCenter: editCostsplit?.destiniCostCenter,
          supportCostCenter: editCostsplit?.supportCostCenter,
          percent: editCostsplit?.percent,
          id: editCostsplit?.id,
          status: editCostsplit?.status,
          observation: editCostsplit?.observation,
        }
      : { key: uuid(), destinationCostCenter: undefined, percent: 0, observation: '', supportCostCenter: undefined },
  ] as ResourceSharing[]);

  const [resourSharingSaveAPI] = useAddResourceSharingMutation();
  const addResourceSharing = () => {
    const list = [...newResourceSharingList];
    list.push({
      key: uuid(),
      destinationCostCenter: undefined,
      percent: 0,
      observation: '',
      supportCostCenter: undefined,
    } as ResourceSharing);
    setNewResourceSharingList(list);
  };
  const removeResourceSharing = (index: number) => {
    const list = [...newResourceSharingList];
    list.splice(index, 1);
    setNewResourceSharingList(list);
  };

  const setResourceSharing = (index: number, property: string, value: CostCenter | number | string) => {
    const list = [...newResourceSharingList];
    const i = list[index];
    i[property] = value;
    setNewResourceSharingList(list);
  };
  const saveResourceSharing = async () => {
    const items = newResourceSharingList.map((resourceSharing) => ({
      id: resourceSharing.id,
      status: resourceSharing.status,
      email: employee?.email || '',
      destiniCostCenterCode: resourceSharing.destinationCostCenter?.code || '',
      supportCostCenterCode:
        costcenter?.branchCode && costcenter?.branchCode !== resourceSharing.destinationCostCenter?.code
          ? resourceSharing.supportCostCenter?.code
          : undefined,
      percent: resourceSharing.percent,
      observation: resourceSharing.observation,
    }));
    await resourSharingSaveAPI({ periodId, document: employee?.document, items });
    incrementLastTimestamp();
    closeDrawer();
  };
  return (
    <div
      style={{
        width: '480px',
        display: 'flex',
        flexDirection: 'column',
        margin: '24px',
        height: 'calc(100vh - 48px)',
        maxHeight: 'calc(100vh - 48px)',
        flexGrow: 1,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
          {t(editCostsplit ? 'edit.costsplit' : 'new.employees.costsplit')}
        </div>
        <div style={{ color: '#666666', fontSize: '14px', fontWeight: 'bold', marginTop: '16px' }}>
          {t('common:collaborator')}
        </div>
        <div style={{ color: '#666666', fontSize: '14px' }}>{employee?.name}</div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ color: '#666666', fontSize: '14px', fontWeight: 'bold', width: '85px', marginTop: '16px' }}>
              {t('document')}
            </div>
            <div style={{ color: '#666666', fontSize: '14px', fontWeight: 'bold', marginTop: '16px' }}>
              {t('contract')}
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ color: '#666666', fontSize: '14px', width: '85px' }}>{employee?.document}</div>
            <div style={{ color: '#666666', fontSize: '14px' }}>{employee?.type}</div>
          </div>
        </div>
        <div style={{ color: '#666666', fontSize: '14px', fontWeight: 'bold', marginTop: '16px' }}>
          {t('origin.cost.center')}
        </div>
        <div style={{ color: '#666666', fontSize: '14px' }}>{`${costcenter?.code} - ${costcenter?.name}`}</div>
        <div style={{ color: '#666666', fontSize: '14px', fontWeight: 'bold', marginTop: '16px' }}>
          {t('origin.cost.center.allocation')}
        </div>
        <div style={{ color: '#666666', fontSize: '14px' }}>{`${employee?.percent}%`}</div>
      </div>
      <div style={{ display: 'flex', flexGrow: 1, margin: '24px 0', overflow: 'auto' }}>
        <AddResourceSharing
          originCostcenter={costcenter}
          edit={!!editCostsplit}
          removeResourceSharing={removeResourceSharing}
          addResourceSharing={addResourceSharing}
          setResourceSharing={setResourceSharing}
          newResourceSharingList={newResourceSharingList}
          totalPercent={employee?.percent || 100}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '8px' }}>
        <Button
          variant="secondary"
          style={{
            marginRight: '16px',
          }}
          onClick={() => closeDrawer()}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          disabled={saveButtonDisabled(newResourceSharingList, costcenter)}
          onClick={() => saveResourceSharing()}
        >
          {t('send')}
        </Button>
      </div>
    </div>
  );
}
