import styled from '@emotion/styled';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

const StatusComponentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function getColor(status: string | undefined | null): string {
  if (status === 'OPENED') {
    return '#FFC107';
  }
  if (status === 'APPROVED') {
    return '#28A745';
  }
  if (status === 'REJECTED') {
    return '#EF4747';
  }
  return '#F97C06';
}

function getStatusText(status: string | undefined | null): string {
  if (status === 'OPENED') {
    return 'Pendente';
  }
  if (status === 'APPROVED') {
    return 'Aprovado';
  }
  if (status === 'REJECTED') {
    return 'Rejeitado';
  }
  return '';
}

function StatusIcon(props: { status: string | undefined | null }) {
  const { status } = props;
  const color = getColor(status);
  if (status === 'OPENED') {
    return <AccessTimeFilledIcon sx={{ color, fontSize: 16 }} />;
  }
  if (status === 'APPROVED') {
    return <CheckCircleIcon sx={{ color, fontSize: 16 }} />;
  }
  if (status === 'REJECTED') {
    return <CancelIcon sx={{ color, fontSize: 16 }} />;
  }
  return <div />;
}

export function StatusComponent(props: { status: string | undefined | null }) {
  const { t } = useTranslation('common');
  const { status } = props;
  return (
    <StatusComponentRow>
      <StatusIcon status={status} />
      <span style={{ marginLeft: '8px', color: getColor(status) }}>{t(getStatusText(status))}</span>
    </StatusComponentRow>
  );
}

export function getPorcentageColor(value: number) {
  if (value < 0) return '#E74C3C';
  return '#ffbf00';
}

export function PercentageBar(props: { value: number; color?: string }) {
  const { value, color = getPorcentageColor(value) } = props;
  return (
    <div style={{ borderRadius: '4px', width: '80px', height: '6px', backgroundColor: '#D9D9D9' }}>
      <div
        style={{
          borderRadius: `${value >= 100 ? '4px' : '4px 0px 0px 4px'}`,
          width: `${80 * (value / 100)}px`,
          height: '6px',
          backgroundColor: color,
        }}
      />
    </div>
  );
}
