import { SharingPrevisionResponse } from '@/app/models/sharingPrevision';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PercentageBar } from '../status/status';

const HeaderTableCell = styled(TableCell)(() => ({
  fontSize: 13,
  fontWeight: 'bold',
  backgroundColor: 'white',
  borderBottom: 'none',
  padding: '0.75rem',
}));

const HeaderItemCell = styled(TableCell)(() => ({
  fontSize: 13,
  backgroundColor: '#e9e9e9',
  borderBottom: 'none',
  padding: '0.5rem',
}));

const RowTableCell = styled(TableCell)(() => ({
  fontSize: 13,
  backgroundColor: 'white',
  borderBottom: 'none',
  padding: '0.5rem',
}));

type SharingPrevisionParams = {
  rows: SharingPrevisionResponse[];
  count: number;
  rowsPerPage: number;
  setRowsPerPage: (n: number) => void;
  page: number;
  setPage: (n: number) => void;
};

const Separator = styled('div')({
  height: '1px',
  backgroundColor: '#666666',
  width: '100%',
  margin: 0,
  padding: 0,
});

export default function SharingPrevisionTable(props: SharingPrevisionParams) {
  const { t } = useTranslation(['sharingPrevision', 'common']);
  const { rows, count, rowsPerPage, setRowsPerPage, page, setPage } = props;

  const groupedRows = rows.reduce((acc, row) => {
    const key = `${row.period} | ${row.resource}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(row);
    return acc;
  }, {} as Record<string, SharingPrevisionResponse[]>);

  return (
    <div>
      <TableContainer sx={{ marginLeft: 0, padding: 0, width: '100%' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>{t('collaborator')}</HeaderTableCell>
              <HeaderTableCell>{t('allocated.percentage')}</HeaderTableCell>
              <HeaderTableCell>{t('requester')}</HeaderTableCell>
              <HeaderTableCell>{t('period')}</HeaderTableCell>
              <HeaderTableCell>{t('last.update')}</HeaderTableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Separator />
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            {Object.entries(groupedRows).map(([period, rowsInPeriod]) => (
              <React.Fragment key={period}>
                {rowsInPeriod.map((row, index) => (
                  <React.Fragment key={row.resource + row.period + row.project}>
                    {index === 0 && (
                      <TableRow>
                        <HeaderItemCell colSpan={6} align="left">
                          {`${row.period} - ${row.resource}`}
                        </HeaderItemCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <RowTableCell colSpan={2}>{row.project}</RowTableCell>
                      <RowTableCell>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <PercentageBar value={row.allocatedPercent} />
                          <span style={{ marginLeft: '8px' }}>{`${row.allocatedPercent} %`}</span>
                        </div>
                      </RowTableCell>
                      <RowTableCell>{row.requestedUser}</RowTableCell>
                      <RowTableCell>{row.period}</RowTableCell>
                      <RowTableCell>{row?.lastUpdate ? row.lastUpdate : '---'}</RowTableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <RowTableCell sx={{ textAlign: 'center' }} colSpan={11}>
                  <Typography variant="subtitle2">
                    {t('common:no.data.for.the.chosen.period.and.search.term')}
                  </Typography>
                </RowTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ width: '100%' }}
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 20));
          setPage(0);
        }}
        labelRowsPerPage={t('common:items.per.page')}
      />
    </div>
  );
}
