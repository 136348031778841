import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import { Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type DateFieldValuearams = {
  initPeriod: dayjs.Dayjs | null;
  setInitPeriod: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
  endPeriod: dayjs.Dayjs | null;
  setEndPeriod: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
};

export default function DateFieldValue(props: DateFieldValuearams) {
  const { initPeriod, setInitPeriod, endPeriod, setEndPeriod } = props;

  const { i18n, t } = useTranslation('periodDetails');
  const { data: initialDate } = useCurrentPeriodQuery();

  const handleInitDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setInitPeriod(newValue.startOf('month'));
      // If endPeriod is now before initPeriod, update it
      if (endPeriod && endPeriod.isBefore(newValue)) {
        setEndPeriod(newValue.add(1, 'month').startOf('month'));
      }
    }
  };

  const handleEndDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue && initPeriod && newValue.isAfter(initPeriod)) {
      setEndPeriod(newValue.startOf('month'));
    }
  };

  useEffect(() => {
    if (initialDate?.initDate) {
      const initData = dayjs(initialDate.initDate).startOf('month');
      setInitPeriod(initData);
      setEndPeriod(initData.add(1, 'month'));
    }
  }, [initialDate, setInitPeriod, setEndPeriod]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <DatePicker
            views={['month', 'year']}
            value={initPeriod}
            onChange={handleInitDateChange}
            sx={{
              width: '190px',
              fontSize: '8px',
            }}
            minDate={initialDate ? dayjs(initialDate.initDate).startOf('month') : undefined}
            slotProps={{ textField: { size: 'small' } }}
          />
        </div>
        <Typography sx={{ margin: '0 8px' }}>{t('à')}</Typography>
        <div>
          <DatePicker
            views={['month', 'year']}
            value={endPeriod}
            onChange={handleEndDateChange}
            sx={{
              width: '190px',
              fontSize: '8px',
            }}
            minDate={initPeriod ? initPeriod.add(1, 'day') : undefined}
            slotProps={{ textField: { size: 'small' } }}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
}
