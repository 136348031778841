import i18n from 'i18next';
// import languageDetector from 'i18next-browser-languagedetector';  //for auto detecting user's language uncomment this line
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  // .use(languageDetector) //for auto detecting user's language uncomment this line
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    ns: [
      'authorization',
      'automaticPeriod',
      'common',
      'costcenter',
      'login',
      'navbar',
      'period',
      'periodDetails',
      'produtos',
      'received',
      'requestsGrid',
      'resourceSharing',
      'sharingPrevision',
      'sidebar',
      'translation',
      'destinationSharing',
    ],
    debug: false,
    lng: 'pt', // for auto detecting user's language uncomment this

    // have a common namespace used around the full app
    defaultNS: 'translation',

    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
