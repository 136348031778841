import api from '@services/api';
import { Contract, NotProcessContract, ProcessContract } from '../models/contract';

/**
 * <p>
 * API endpoints that covers all Contract CRUD calls
 */

export interface ContractQueryResponse {
  content: Contract[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

interface QueryInput {
  query: string;
  showClosed: boolean;
}

export const contractApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // SEARCH BY NAME
    contractSearchResource: builder.query<ContractQueryResponse, QueryInput>({
      query: (queryInput) => ({
        url: `/sharing/contract/searchByResource?searchTerm=${queryInput.query}&bringClosed=${queryInput.showClosed}&page=0&size=10`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Contract'],
    }),
    // LIST ALL NOT PROCESSED CONTRACT
    notProcessContract: builder.query<NotProcessContract[], void>({
      query: () => ({
        url: '/sharing/contract/listAllNotProcessedContracts',
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['NotProcessContract'],
    }),
    processContract: builder.mutation<void, ProcessContract>({
      query: (contractIds) => ({
        url: '/sharing/contract/process',
        method: 'POST',
        body: contractIds,
      }),
      invalidatesTags: ['NotProcessContract'],
    }),
  }),
});

export const { useContractSearchResourceQuery, useNotProcessContractQuery, useProcessContractMutation } = contractApi;
