import { ON_CHANGE_DELAY } from '@/app/constants/values';
import { CostCenter } from '@/app/models/costcenter';
import { useCostCentersSearchNameQuery } from '@/app/services/costcenterApi';
import searchIcon from '@/icons/search.svg';
import { useThrottle } from 'react-use';

import { SharingOnDestination } from '@/app/models/summary';
import { useSharingOnDestinationQuery } from '@/app/services/resourceSharingApi';
import { getColor, PercentageBar, StatusComponent } from '@/components/status/status';
import {
  Autocomplete,
  Box,
  InputAdornment,
  InputProps,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchBarsCostCenterProps {
  setSelectedCostCenter: (arg0: string) => void;
  setSelectedCostCenterName: (arg0: string) => void;
}

export function SearchBarsCostCenter(props: SearchBarsCostCenterProps) {
  const { setSelectedCostCenter, setSelectedCostCenterName } = props;

  const [costCenter, setCostCenter] = useState('');
  const [searchCostCenter, setSearchCostCenter] = useState('===');
  const throttledSearchCostCenter = useThrottle(searchCostCenter, ON_CHANGE_DELAY);
  const { data: costCentersByName } = useCostCentersSearchNameQuery({
    query: throttledSearchCostCenter,
    showClosed: false,
  });

  const { t } = useTranslation('destinationSharing');

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', ml: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '18px', color: '#4b4b4b', marginBottom: '8px', marginTop: '32px' }}
        >
          {t('destination.sharing')}
        </Typography>
        <Typography sx={{ fontSize: '13px', color: '#888888', marginBottom: '24px' }}>
          {t('destination.sharing.subtitle')}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          width: '50%',
          justifyContent: 'space-between',
          marginTop: '23px',
          marginBottom: '22px',
        }}
      >
        <div style={{ display: 'flex', width: '70%', margin: 'auto 0' }}>
          <div style={{ width: '100%', marginRight: '30px' }}>
            <div style={{ display: 'flex', marginBottom: '8px', marginTop: '8px', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#4b4b4b' }}>
                {t('result.center')}
              </Typography>
              <Typography sx={{ fontSize: '12px', color: '#888888', marginLeft: '4px' }}>
                {`(${t('destination')})`}
              </Typography>
            </div>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              style={{ width: '100%', marginRight: '30px' }}
              size="small"
              onChange={(event, newValue) => {
                if (newValue && (newValue as CostCenter).code) {
                  setSelectedCostCenter((newValue as CostCenter).code);
                  setSelectedCostCenterName((newValue as CostCenter).name);
                }
              }}
              inputValue={costCenter}
              onInputChange={(event, newInputValue) => {
                setCostCenter(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchCostCenter(newInputValue);
                }
              }}
              options={costCentersByName ? costCentersByName.content.map((option: CostCenter) => option) : []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {option.code} {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('search.icon') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('type.cost.center.name.code') as string}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div />
    </Box>
  );
}

function Destination() {
  const tableEl = useRef() as unknown as MutableRefObject<HTMLInputElement>;
  const { t } = useTranslation(['destinationSharing', 'common']);
  const [selectedCostCenter, setSelectedCostCenter] = useState('');
  const [selectedCostCenterName, setSelectedCostCenterName] = useState('');

  const { data: destinationSharings } = useSharingOnDestinationQuery(selectedCostCenter, { skip: !selectedCostCenter });

  const HeaderItemCell = styled(TableCell)(() => ({
    fontSize: 13,
    backgroundColor: '#E9E9E9',
    borderBottom: 'none',
    padding: '0.5rem',
  }));
  return (
    <div style={{ width: '100%' }}>
      <SearchBarsCostCenter
        setSelectedCostCenter={setSelectedCostCenter}
        setSelectedCostCenterName={setSelectedCostCenterName}
      />

      <div>
        {destinationSharings && (
          <TableContainer component={Paper} style={{ maxHeight: '60vh', marginTop: '32px' }} ref={tableEl}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ color: '#4B4B4B', height: '40px', fontSize: '13px' }}>
                  <TableCell style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}>
                    {t('colaborators')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}>
                    {t('allocated.perc')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}>
                    {t('common:status')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}>
                    {t('approver')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}>
                    {t('origin')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {destinationSharings.length > 0 && (
                  <TableRow>
                    <HeaderItemCell colSpan={5} align="left">{`${t(
                      'destination',
                    )} - ${selectedCostCenter} - ${selectedCostCenterName}`}</HeaderItemCell>
                  </TableRow>
                )}
                {destinationSharings.length > 0 &&
                  destinationSharings?.map((sharing: SharingOnDestination, i) => (
                    <TableRow
                      key={sharing.badge + sharing.originApproverName + sharing.originProjectCode}
                      sx={{
                        height: '40px',
                        border: '0px',
                        cursor: 'pointer',
                        backgroundColor: `${i % 2 === 0 ? '#FFFFFF' : '#FAFAFA'}`,
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}
                        align="left"
                      >
                        {`${sharing.badge} - ${sharing.resourceName}`}
                      </TableCell>
                      <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <PercentageBar value={sharing.percent} color={getColor(sharing?.status)} />
                          <span style={{ marginLeft: '8px' }}>{`${sharing.percent} %`}</span>
                        </div>
                      </TableCell>
                      <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                        <StatusComponent status={sharing?.status} />
                      </TableCell>
                      <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                        {sharing.originApproverName}
                      </TableCell>
                      <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                        {`${sharing.originProjectCode} - ${sharing.originProjectName}`}
                      </TableCell>
                    </TableRow>
                  ))}
                {destinationSharings.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '360px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Typography>{t('no.sharing.destination.foiund')}</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}

export default Destination;
