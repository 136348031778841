import api from '@services/api';
import { PaginationResponse } from '../models/pagination';
import { ApproveRejectRequest, SharingOnDestination, SharingResource, SharingSummary } from '../models/summary';

interface SummaryRequest {
  period: number;
  email: string;
}

interface PeriodAndOriginCostCenter {
  period: number;
  costcenterCode: string;
  timestamp: number;
}

type ExcludeSharing = {
  id: number;
};

type ResourceSharingItem = {
  email: string;
  destiniCostCenterCode: string;
  supportCostCenterCode: string | undefined;
  percent: number;
  observation: string | undefined;
};

interface ResourceSharingList {
  periodId: number;
  document?: string;
  items: Array<ResourceSharingItem>;
}

export interface SharingsRequestedRequest {
  period: number;
  email?: string;
  status?: string;
  searchTerm?: string;
  page: number;
  size: number;
}

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */
export const resourceSharingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    sharingSummary: builder.query<SharingSummary, SummaryRequest>({
      query: (input: SummaryRequest) => ({
        url: encodeURI(`/sharing/resource-sharing/summary?id=${input.period}&email=${input.email}`),
      }),
      providesTags: ['Summary'],
    }),
    sharingByPeriodAndOriginCostCenter: builder.query<Array<SharingResource>, PeriodAndOriginCostCenter>({
      query: (input: PeriodAndOriginCostCenter) => ({
        url: encodeURI(
          `/sharing/resource-sharing/byPeriodAndOriginCostCenter?id=${input.period}&code=${input.costcenterCode}`,
        ),
      }),
      providesTags: ['Summary'],
    }),
    sharingOnDestination: builder.query<Array<SharingOnDestination>, string>({
      query: (input: string) => ({
        url: encodeURI(`/sharing/resource-sharing/findSharingsOnDestini?projectCode=${input}`),
      }),
      providesTags: ['Summary'],
    }),
    sharingsRequested: builder.query<PaginationResponse<SharingResource>, SharingsRequestedRequest>({
      query: (input: SharingsRequestedRequest) => ({
        url: encodeURI(
          `/sharing/resource-sharing/findRequestedSharings?periodId=${input.period}&page=${input.page}&size=${
            input.size
          }${input.status ? `&status=${input.status}` : ''}${
            input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''
          }${input.email ? `&email=${input.email}` : ''}`,
        ),
      }),
      providesTags: ['ResourceSharing'],
    }),
    sharingsReceived: builder.query<PaginationResponse<SharingResource>, SharingsRequestedRequest>({
      query: (input: SharingsRequestedRequest) => ({
        url: encodeURI(
          `/sharing/resource-sharing/findReceivedSharings?periodId=${input.period}&page=${input.page}&size=${
            input.size
          }${input.status ? `&status=${input.status}` : ''}${
            input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''
          }${input.email ? `&email=${input.email}` : ''}`,
        ),
      }),
      providesTags: ['ResourceSharing'],
    }),
    // approve multiple
    approveAll: builder.mutation<ApproveRejectRequest, ApproveRejectRequest>({
      query: (input: ApproveRejectRequest) => ({
        url: '/sharing/resource-sharing/approvals',
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['ResourceSharing', 'Summary'],
    }),
    // reject multiple
    rejectAll: builder.mutation<ApproveRejectRequest, ApproveRejectRequest>({
      query: (input: ApproveRejectRequest) => ({
        url: '/sharing/resource-sharing/rejectAll',
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['ResourceSharing', 'Summary'],
    }),
    // CREATE NEW
    addResourceSharing: builder.mutation<void, ResourceSharingList>({
      query: (resourceSharingList: ResourceSharingList) => ({
        url: '/sharing/resource-sharing/share',
        method: 'POST',
        body: resourceSharingList,
      }),
      invalidatesTags: ['ResourceSharing', 'Summary'],
    }),
    // DELETE RESOURCE SHARING
    deleteResourceSharing: builder.mutation<void, ExcludeSharing>({
      query: (input: ExcludeSharing) => ({
        url: '/sharing/resource-sharing/delete',
        method: 'DELETE',
        body: input,
      }),
      invalidatesTags: ['ResourceSharing', 'Summary'],
    }),
  }),
});

export const {
  useSharingSummaryQuery,
  useSharingByPeriodAndOriginCostCenterQuery,
  useSharingOnDestinationQuery,
  useSharingsRequestedQuery,
  useSharingsReceivedQuery,
  useApproveAllMutation,
  useRejectAllMutation,
  useAddResourceSharingMutation,
  useDeleteResourceSharingMutation,
} = resourceSharingApi;
