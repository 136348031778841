import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/costCenterDetails.css';

import { CostCenter } from '@/app/models/costcenter';
import CreateResourceSharing from '@/components/costcenter/CreateResourceSharing';
import { getColor, PercentageBar, StatusComponent } from '@/components/status/status';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer } from '@mui/material';

type CallbackFunction = (employee: Employee) => void;
type CallbackDeleteFunction = (id: number) => void;

type CallbackClickFunction = () => void;

export type CostSplit = {
  id: number;
  destiniCostCenter: CostCenter;
  supportCostCenter?: CostCenter;
  approverName?: string;
  observation?: string;
  status?: string;
  percent: number;
};
export type Employee = {
  id?: number;
  name?: string;
  email?: string;
  type?: string;
  document?: string;
  costsplits: Array<CostSplit>;
  percent: number;
};

type CostcenterDetailsTableProp = {
  employees: Array<Employee>;
  searchName?: string;
  createNewResourceSharing: CallbackFunction;
  deleteResourceSharing: CallbackDeleteFunction;
  costcenter: CostCenter;
  periodId: number;
  incrementLastTimestamp: CallbackClickFunction;
  canEdit: boolean;
};

type EditAndDeleteProps = {
  onClickEdit: CallbackClickFunction;
  onClickDelete: CallbackClickFunction;
  editText: string;
  deleteText: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
};

const EditAndDeleteTooltip = styled(
  ({ className, children, onClickEdit, onClickDelete, editText, deleteText }: EditAndDeleteProps) => (
    <Tooltip
      arrow
      placement="left"
      classes={{ popper: className }}
      title={
        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="contained"
            style={{
              height: '32px',
              color: '#666666',
              fontSize: '13px',
              textTransform: 'initial',
              backgroundColor: '#FFFFFF',
              width: '100%',
              borderRadius: '0px',
            }}
            onClick={() => onClickEdit()}
          >
            <div style={{ display: 'flex' }}>
              <EditIcon sx={{ color: '#4B4B4B', fontSize: 16 }} />
              <span style={{ marginLeft: '8px' }}>{editText}</span>
            </div>
          </Button>

          <Button
            variant="contained"
            style={{
              height: '32px',
              color: '#666666',
              fontSize: '13px',
              textTransform: 'initial',
              backgroundColor: '#FFFFFF',
              width: '100%',
              borderRadius: '0px',
            }}
            onClick={() => onClickDelete()}
          >
            <div style={{ display: 'flex' }}>
              <DeleteIcon sx={{ color: '#4B4B4B', fontSize: 16 }} />
              <span style={{ marginLeft: '8px' }}>{deleteText}</span>
            </div>
          </Button>
        </div>
      }
    >
      {children}
    </Tooltip>
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    padding: '1px',
  },
}));

function CostSplitRow(props: {
  employee: Employee;
  costsplit: CostSplit;
  deleteResourceSharing: CallbackDeleteFunction;
  originCostcenter: CostCenter;
  periodId: number;
  incrementLastTimestamp: CallbackClickFunction;
  canEdit: boolean;
}) {
  const { t } = useTranslation('resourceSharing');
  const { costsplit, deleteResourceSharing, originCostcenter, employee, periodId, incrementLastTimestamp, canEdit } =
    props;

  const [openDeleteResourceSharing, setOpenDeleteResourceSharing] = useState(false);

  const [openNewResourceDrawer, setOpenNewResourceDrawer] = useState(false);

  const handleClickOpenDeleteResourceSharing = () => {
    setOpenDeleteResourceSharing(true);
  };

  const onClickEdit = () => {
    if (canEdit) {
      setOpenNewResourceDrawer(true);
    }
  };

  const handleCloseDeleteResourceSharing = () => {
    setOpenDeleteResourceSharing(false);
  };
  const onClickDelete = () => {
    if (canEdit) {
      deleteResourceSharing(costsplit.id);
      setOpenDeleteResourceSharing(false);
    }
  };
  const closeDrawer = () => {
    setOpenNewResourceDrawer(false);
  };
  return (
    <>
      <Drawer onBackdropClick={() => setOpenNewResourceDrawer(false)} open={openNewResourceDrawer} anchor="right">
        <CreateResourceSharing
          editCostsplit={costsplit}
          closeDrawer={closeDrawer}
          periodId={periodId}
          employee={employee}
          incrementLastTimestamp={incrementLastTimestamp}
          costcenter={originCostcenter}
        />
      </Drawer>
      <Dialog
        open={openDeleteResourceSharing}
        onClose={handleCloseDeleteResourceSharing}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {' '}
        <DialogTitle id="alert-dialog-title">{t('deleting.costsplit')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('are.you.sure.in.deleting.this.costsplit')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              height: '32px',
              color: '#666666',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'initial',
              backgroundColor: '#F2F2F2',
            }}
            onClick={handleCloseDeleteResourceSharing}
          >
            {t('no')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              marginLeft: '16px',
              height: '32px',
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'initial',
            }}
            onClick={onClickDelete}
            autoFocus
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow
        key={`${costsplit.destiniCostCenter.code}`}
        style={{ minHeight: '40px', height: '40px', maxHeight: '40px' }}
      >
        <TableCell
          style={{
            width: '20%',
            maxWidth: '20%',
            minWidth: '20%',
            fontSize: '13px',
            padding: '0px 0px 0px 40px',
            position: 'relative',
            overflow: 'hidden',
          }}
          component="td"
          scope="row"
          colSpan={3}
        >
          <div
            style={{
              position: 'absolute',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              height: '40px',
              fontSize: '13px',
              top: 0,
              paddingRight: '10px',
              maxWidth: '100%',
              lineHeight: '40px',
            }}
          >
            {`${costsplit.destiniCostCenter.code} - ${costsplit.destiniCostCenter.name}`}
          </div>
        </TableCell>
        <TableCell style={{ width: '80px', fontSize: '13px', padding: 0 }} colSpan={1} />
        <TableCell style={{ width: '80px', fontSize: '13px', padding: 0 }} colSpan={1} />
        <TableCell align="left" style={{ width: '160px', fontSize: '13px', padding: 0 }} colSpan={1}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <PercentageBar value={costsplit.percent} color={getColor(costsplit?.status)} />
            <span style={{ marginLeft: '8px' }}>{`${costsplit.percent} %`}</span>
          </div>
        </TableCell>
        <TableCell
          style={{ width: '150px', fontSize: '13px', padding: 0 }}
          align="left"
          component="td"
          scope="row"
          colSpan={1}
        >
          <StatusComponent status={costsplit?.status} />
        </TableCell>
        <TableCell
          style={{
            width: '20%',
            maxWidth: '20%',
            minWidth: '20%',
            fontSize: '13px',
            padding: 0,
            position: 'relative',
            overflow: 'hidden',
          }}
          align="left"
          component="td"
          scope="row"
          colSpan={1}
        >
          <div
            style={{
              position: 'absolute',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              height: '40px',
              fontSize: '13px',
              top: 0,
              paddingRight: '10px',
              maxWidth: '100%',
              lineHeight: '40px',
            }}
          >
            {costsplit.approverName}
          </div>
        </TableCell>
        <TableCell
          style={{
            position: 'relative',
            overflow: 'hidden',
            padding: 0,
          }}
          align="left"
          scope="row"
          colSpan={1}
        >
          <div
            style={{
              position: 'absolute',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              height: '40px',
              fontSize: '13px',
              top: 0,
              paddingRight: '10px',
              maxWidth: '100%',
              lineHeight: '40px',
            }}
          >
            {costsplit.observation}
          </div>
        </TableCell>
        <TableCell
          align="left"
          style={{ minWidth: '50px', width: '50px', fontSize: '13px', padding: 0 }}
          scope="row"
          component="td"
          colSpan={1}
        >
          {canEdit && (
            <EditAndDeleteTooltip
              className=""
              onClickEdit={onClickEdit}
              onClickDelete={() => handleClickOpenDeleteResourceSharing()}
              editText={t('edit.costsplit')}
              deleteText={t('delete.costsplit')}
            >
              <MoreVertIcon className="action" />
            </EditAndDeleteTooltip>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

function Row(props: {
  employee: Employee;
  createNewResourceSharing: CallbackFunction;
  deleteResourceSharing: CallbackDeleteFunction;
  costcenter: CostCenter;
  periodId: number;
  incrementLastTimestamp: CallbackClickFunction;
  canEdit: boolean;
}) {
  const {
    employee,
    createNewResourceSharing,
    deleteResourceSharing,
    costcenter,
    periodId,
    incrementLastTimestamp,
    canEdit,
  } = props;

  const open = true;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(canEdit && true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          cursor: canEdit ? 'pointer' : 'default',
          backgroundColor: '#F5F5F5',
          minHeight: '40px',
          height: '40px',
          maxHeight: '40px',
        }}
        style={{ fontSize: '13px' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (canEdit) {
            createNewResourceSharing(employee);
          }
        }}
      >
        <TableCell
          style={{
            padding: '0px 0px 0px 16px',
            width: '20%',
            maxWidth: '20%',
            minWidth: '20%',
            fontSize: '13px',
            position: 'relative',
            overflow: 'hidden',
          }}
          align="left"
          component="td"
        >
          <div
            style={{
              position: 'absolute',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              height: '40px',
              fontSize: '13px',
              top: 0,
              paddingRight: '10px',
              maxWidth: '100%',
              lineHeight: '40px',
            }}
          >
            {employee.name}
          </div>
        </TableCell>
        <TableCell align="left" style={{ width: '80px', fontSize: '13px', padding: 0 }}>
          {employee.document}
        </TableCell>
        <TableCell align="left" style={{ width: '80px', fontSize: '13px', padding: 0 }}>
          {employee.type}
        </TableCell>
        <TableCell align="left" style={{ width: '160px', fontSize: '13px', padding: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <PercentageBar value={employee.percent} color="#F97C06" />
            <span style={{ marginLeft: '8px' }}>{`${employee.percent} %`}</span>
          </div>
        </TableCell>
        <TableCell align="right" style={{ width: '150px', fontSize: '13px', padding: 0 }} />
        <TableCell align="right" style={{ width: '20%', fontSize: '13px', padding: 0 }} />
        <TableCell align="right" style={{ maxWidth: '20%', minWidth: '20%', fontSize: '13px', padding: 0 }} />
        <TableCell align="left" style={{ width: '50px', fontSize: '13px', padding: 0 }}>
          {isHovering && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <AddIcon />
            </div>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="purchases">
                <TableBody>
                  {(employee.costsplits || []).map((costsplit) => (
                    <CostSplitRow
                      key={costsplit.id}
                      employee={employee}
                      costsplit={costsplit}
                      deleteResourceSharing={deleteResourceSharing}
                      originCostcenter={costcenter}
                      periodId={periodId}
                      incrementLastTimestamp={incrementLastTimestamp}
                      canEdit={canEdit}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function filterEmployees(employees: Array<Employee>, searchName: string): Array<Employee> {
  return employees.filter(
    (employee) =>
      (employee.name && employee.name.toUpperCase().includes(searchName.toUpperCase())) ||
      (employee.document && employee.document.toUpperCase().includes(searchName.toUpperCase())),
  );
}

export default function CostCenterDetailsTable(props: CostcenterDetailsTableProp) {
  const { t } = useTranslation(['resourceSharing', 'common']);
  const {
    employees,
    searchName,
    createNewResourceSharing,
    deleteResourceSharing,
    costcenter,
    periodId,
    incrementLastTimestamp,
    canEdit,
  } = props;
  const filteredEmployees = searchName ? filterEmployees(employees, searchName) : employees;
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Table
          aria-label="collapsible table"
          sx={{
            height: 'max-content',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                minHeight: '40px',
                height: '40px',
                maxHeight: '40px',
              }}
            >
              <TableCell
                style={{
                  width: '20%',
                  maxWidth: '20%',
                  minWidth: '20%',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: '0px 0px 0px 16px',
                }}
                align="left"
              >
                {`${t('common:collaborator')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '80px',
                  maxWidth: '80px',
                  minWidth: '80px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('document')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '80px',
                  maxWidth: '80px',
                  minWidth: '80px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('contract')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '160px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('percentage')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '150px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('Status')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                  maxWidth: '20%',
                  minWidth: '20%',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('approver')}`}
              </TableCell>
              <TableCell
                style={{
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('observation')}`}
              </TableCell>
              <TableCell
                style={{
                  width: '50px',
                  maxWidth: '50px',
                  minWidth: '50px',
                  fontSize: '13px',
                  color: '#4B4B4B',
                  fontWeight: 'bold',
                  padding: 0,
                }}
                align="left"
              >
                {`${t('common:actions')}`}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </div>

      <div style={{ overflow: 'auto', width: '100%' }}>
        <Table style={{ tableLayout: 'fixed', overflow: 'auto', width: '100%' }}>
          <TableBody style={{ overflow: 'auto' }}>
            {filteredEmployees.map((employee) => (
              <Row
                key={`${employee.email}-${employee.document}`}
                employee={employee}
                createNewResourceSharing={createNewResourceSharing}
                deleteResourceSharing={deleteResourceSharing}
                costcenter={costcenter}
                periodId={periodId}
                incrementLastTimestamp={incrementLastTimestamp}
                canEdit={canEdit}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}
