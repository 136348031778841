import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ON_CHANGE_DELAY from '@/app/constants/values';
import { Contract } from '@/app/models/contract';
import { useContractSearchResourceQuery } from '@/app/services/contractApi';
import { useSharingPrevisionQuery } from '@/app/services/sharingPrevisionApi';
import PeriodDetails from '@/components/PeriodDetails';
import SharingPrevisionPeriod from '@/components/predictionsSharing/SharingPrevisionPeriod';
import PredicationsSharingTable from '@/components/predictionsSharing/SharingPrevisionTable';
import searchIcon from '@/icons/search.svg';
import HelpIcon from '@mui/icons-material/Help';
import { Autocomplete, Box, InputAdornment, InputProps, TextField, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useThrottle } from 'react-use';

export default function SharingPrevision() {
  const { t } = useTranslation(['sharingPrevision', 'common']);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  const [initPeriod, setInitPeriod] = useState<dayjs.Dayjs | null>(dayjs());
  const [endPeriod, setEndPeriod] = useState<dayjs.Dayjs | null>(dayjs());

  const [contract, setContract] = useState('');
  const [searchContract, setSearchContract] = useState('===');
  const throttledSearchContract = useThrottle(searchContract, ON_CHANGE_DELAY);
  const { data: contractsByName } = useContractSearchResourceQuery({
    query: throttledSearchContract,
    showClosed: false,
  });

  const [selectedCollaborator, setSelectedCollaborator] = useState('');

  const initDate = initPeriod?.format('YYYY-MM-DD') || '';
  const endDate = endPeriod?.format('YYYY-MM-DD') || '';
  const { data: sharingPrevisionData } = useSharingPrevisionQuery(
    {
      initPeriod: initDate,
      endPeriod: endDate,
      personSearchTerm: selectedCollaborator,
      page,
      size,
    },
    {
      skip: !selectedCollaborator,
    },
  );

  return (
    <div style={{ height: '100%', width: '100%', marginRight: '10px' }}>
      <PeriodDetails />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '32px',
        }}
      >
        <div>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'none' }}>
            {t('collaborator')}
            <Typography sx={{ marginLeft: '2px', fontSize: '13px', textTransform: 'none', display: 'inline-flex' }}>
              {`(${t('prediction.sharing')})`}
            </Typography>
            <Tooltip
              arrow
              enterDelay={200}
              placement="top-end"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#F7F7F7',
                    minWidth: '60rem',
                    border: '1px solid #D9D9D9',
                    color: 'black',
                    '& .MuiTooltip-arrow': {
                      color: '#F7F7F7',
                      '&:before': {
                        border: '1px solid #D9D9D9',
                      },
                    },
                  },
                },
              }}
              title={
                <div>
                  <Typography sx={{ display: 'inline', fontSize: '13px' }}>
                    <span style={{ fontWeight: 'bold' }}>{t('prediction.rules')}</span>
                    {t('listed.below.are.the.apportionment.forecasts.provided.by')}
                    <span style={{ textDecoration: 'underline' }}>{t('financial.spreadsheet')}</span>
                    {t('message.previous.periods.deleted')}
                    <span style={{ textDecoration: 'underline' }}>{t('current.month')}</span>
                  </Typography>
                  <Typography sx={{ display: 'inline', fontSize: '13px' }}>
                    {t('message.lost.error.in.prediction')}
                    <span style={{ textDecoration: 'underline' }}>{t('reason')}</span>
                    {t('through.the.highlighted.lines.of')}
                    <span style={{ color: 'red' }}>{t('red')}</span>
                    {t('message.does.not.exist.functionality.modify.field.adjust.your')}
                    <span style={{ textDecoration: 'underline' }}>{t('financial.spreadsheet')}</span>
                    {t('message.send.again.sharing.prevision')}
                  </Typography>
                </div>
              }
            >
              <HelpIcon sx={{ fontSize: 16, color: '#4682B4', marginLeft: '4px' }} />
            </Tooltip>
          </Typography>
          <Autocomplete
            freeSolo
            filterOptions={(option) => option}
            isOptionEqualToValue={(option, value) => option.document === value.document}
            size="small"
            style={{ width: '25rem', marginRight: '30px' }}
            onChange={(event, newValue) => {
              if (newValue && (newValue as Contract).costCenterCode && (newValue as Contract).name) {
                setSelectedCollaborator((newValue as Contract).name);
              }
            }}
            inputValue={contract}
            onInputChange={(event, newInputValue) => {
              setContract(newInputValue);
              if (newInputValue.length >= 1) {
                setSearchContract(newInputValue);
              }
            }}
            options={contractsByName ? contractsByName.content.map((option: Contract) => option) : []}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            renderOption={(params, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                {option.document} {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& input': {
                    padding: '0px',
                    height: '2.45rem',
                  },
                }}
                size="small"
                style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                ref={params.InputProps.ref}
                autoFocus
                placeholder={t('common:type.collaborator.name.badge') as string}
                InputProps={
                  {
                    ...params.inputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={searchIcon} alt={t('search.icon') as string} />
                      </InputAdornment>
                    ),
                    style: {
                      paddingRight: '10px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '10px',
                      fontSize: '13px',
                    },
                  } as InputProps
                }
              />
            )}
          />
        </div>
        <div style={{ marginLeft: '1rem', marginTop: '0.5rem' }}>
          <Typography
            sx={{
              fontSize: '0.85rem',
              textTransform: 'none',
            }}
          >
            {t('common:period')}
          </Typography>
          <SharingPrevisionPeriod
            initPeriod={initPeriod}
            setInitPeriod={setInitPeriod}
            endPeriod={endPeriod}
            setEndPeriod={setEndPeriod}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
        <PredicationsSharingTable
          rows={sharingPrevisionData?.content || []}
          count={sharingPrevisionData?.totalElements || 0}
          page={page}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
        />
      </div>
    </div>
  );
}
