import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import { useSharingSummaryQuery } from '@/app/services/resourceSharingApi';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeView from '@mui/lab/TreeView';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './SidebarItem';

export default function Sidebar() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['sidebar', 'common', 'automaticPeriod']);
  const { data: currentPeriod, isSuccess: hasPeriod } = useCurrentPeriodQuery();
  const isDev = process.env.REACT_APP_ENV === 'dev';
  const isPMO = keycloak.hasResourceRole('PMO', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || isDev;
  const isAdmin = keycloak.hasResourceRole('ADMIN', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || isDev;
  const isSuper = keycloak.hasResourceRole('SUPER', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || isDev;
  const isGerente = keycloak.hasResourceRole('MANAGER', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || isDev;
  const isPrevision = keycloak.hasResourceRole('PREVISION', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || isDev;
  const isDestination = keycloak.hasResourceRole('DESTINATION', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || isDev;

  const { data, isSuccess } = useSharingSummaryQuery(
    {
      period: currentPeriod?.id || 0,
      email: keycloak?.tokenParsed?.email,
    },
    { skip: !hasPeriod },
  );

  const selectedTree = (endpoint: string) => {
    switch (endpoint) {
      case '/dashboard':
        return '1';
      case '/costcenter':
        return '2';
      case '/received':
        return '3';
      case '/requested':
        return '4';
      case '/period':
        return '5';
      case '/automatic-period':
        return '6';
      case '/predictions-sharing':
        return '7';
      case '/product':
        return '9';
      case '/permission-by-product':
        return '10';
      case '/destination-sharing':
        return '11';
      default:
        return '1';
    }
  };
  return (
    <TreeView
      selected={selectedTree(location.pathname)}
      aria-label="gmail"
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 250, paddingLeft: '2rem' }}
    >
      {(isGerente || isPMO) && (
        <>
          <SidebarItem
            active={selectedTree(location.pathname) === '2'}
            nodeId="2"
            labelText={t('common:cost.centers')}
            onClick={() => {
              navigate('costcenter');
            }}
          />
          <Typography sx={{ marginY: '1rem', fontWeight: 'bold' }}>{t('requests')}</Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '3'}
            nodeId="3"
            labelText={t('received')}
            labelInfo="2,294"
            color="#e3742f"
            bgColor="#fcefe3"
            quant={isSuccess ? data?.received : undefined}
            badgeColor="warning"
            onClick={() => {
              navigate('received');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '4'}
            nodeId="4"
            labelText={t('sent')}
            labelInfo="3,566"
            color="#e3742f"
            bgColor="#fcefe3"
            quant={isSuccess ? data?.requested : undefined}
            badgeColor="warning"
            onClick={() => {
              navigate('requested');
            }}
          />
        </>
      )}
      <>
        {(isDestination || isPrevision || isGerente) && (
          <Typography sx={{ marginY: '1rem', fontWeight: 'bold' }}>{t('views')}</Typography>
        )}
        {isPrevision && (
          <SidebarItem
            active={selectedTree(location.pathname) === '7'}
            nodeId="7"
            labelText={t('predictions.sharing')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('predictions-sharing');
            }}
          />
        )}
        {(isDestination || isGerente) && (
          <SidebarItem
            active={selectedTree(location.pathname) === '11'}
            nodeId="11"
            labelText={t('destination.sharing')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('destination-sharing');
            }}
          />
        )}
      </>
      {isPMO && (
        <>
          <Typography sx={{ marginY: '1rem', fontWeight: 'bold' }}>{t('settings')}</Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '5'}
            nodeId="5"
            labelText={t('common:period')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('period');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '6'}
            nodeId="6"
            labelText={t('automaticPeriod:auto.routine')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('automatic-period');
            }}
          />
        </>
      )}
      {(isAdmin || isSuper) && (
        <>
          <Typography sx={{ marginY: '1rem', fontWeight: 'bold' }}>{t('admin')}</Typography>
          {isSuper && (
            <SidebarItem
              active={selectedTree(location.pathname) === '9'}
              nodeId="9"
              labelText={t('common:products')}
              color="#e3742f"
              bgColor="#fcefe3"
              onClick={() => {
                navigate('product');
              }}
            />
          )}
          <SidebarItem
            active={selectedTree(location.pathname) === '10'}
            nodeId="10"
            labelText={t('product.permission')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('permission-by-product');
            }}
          />
        </>
      )}
    </TreeView>
  );
}
