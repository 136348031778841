import { ON_CHANGE_DELAY } from '@/app/constants/values';
import { Contract } from '@/app/models/contract';
import { CostCenter } from '@/app/models/costcenter';
import { CostCenterResource } from '@/app/models/costCenterResource';
import { useContractSearchResourceQuery } from '@/app/services/contractApi';
import { useCostCentersSearchNameQuery } from '@/app/services/costcenterApi';
import { useCostCenterResourceQuery } from '@/app/services/costCenterResouceApi';
import searchIcon from '@/icons/search.svg';
import { useThrottle } from 'react-use';

import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useKeycloak } from '@react-keycloak/web';
import { MutableRefObject, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SearchBarsCostCenterProps {
  showClosed: boolean;
  setShowClosed: (arg0: boolean) => void;
}

export function SearchBarsCostCenter(props: SearchBarsCostCenterProps) {
  const navigate = useNavigate();
  const { showClosed, setShowClosed } = props;

  const [costCenter, setCostCenter] = useState('');
  const [searchCostCenter, setSearchCostCenter] = useState('===');
  const throttledSearchCostCenter = useThrottle(searchCostCenter, ON_CHANGE_DELAY);
  const { data: costCentersByName } = useCostCentersSearchNameQuery({ query: throttledSearchCostCenter, showClosed });

  const [contract, setContract] = useState('');
  const [searchContract, setSearchContract] = useState('===');
  const throttledSearchContract = useThrottle(searchContract, ON_CHANGE_DELAY);
  const { data: contractsByName } = useContractSearchResourceQuery({ query: throttledSearchContract, showClosed });

  const { t } = useTranslation(['costcenter', 'common']);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', ml: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '23px',
          marginBottom: '22px',
        }}
      >
        <div style={{ display: 'flex', width: '70%', margin: 'auto 0' }}>
          <div style={{ width: '100%', marginRight: '30px' }}>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              style={{ width: '100%', marginRight: '30px' }}
              size="small"
              onChange={(event, newValue) => {
                if (newValue && (newValue as CostCenter).code) {
                  console.log(newValue);
                  navigate(`${(newValue as CostCenter).code}`);
                }
              }}
              inputValue={costCenter}
              onInputChange={(event, newInputValue) => {
                setCostCenter(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchCostCenter(newInputValue);
                }
              }}
              options={costCentersByName ? costCentersByName.content.map((option: CostCenter) => option) : []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {option.code} {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('search.icon') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('type.cost.center.name.code') as string}
                />
              )}
            />
            <FormControlLabel
              sx={{ whiteSpace: 'nowrap', fontSize: '12px' }}
              control={
                <Checkbox
                  color="secondary"
                  checked={showClosed}
                  onChange={() => {
                    setShowClosed(!showClosed);
                  }}
                />
              }
              label={<Typography sx={{ fontSize: '12px' }}>{t('show.closed.cost.centers')}</Typography>}
            />
          </div>
          <Autocomplete
            freeSolo
            filterOptions={(option) => option}
            isOptionEqualToValue={(option, value) => option.document === value.document}
            size="small"
            style={{ width: '100%', marginRight: '30px' }}
            onChange={(event, newValue) => {
              if (newValue && (newValue as Contract).costCenterCode && (newValue as Contract).name) {
                navigate(`${(newValue as Contract).costCenterCode}?name=${(newValue as Contract).name}`);
              }
            }}
            inputValue={contract}
            onInputChange={(event, newInputValue) => {
              setContract(newInputValue);
              if (newInputValue.length >= 1) {
                setSearchContract(newInputValue);
              }
            }}
            options={contractsByName ? contractsByName.content.map((option: Contract) => option) : []}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            renderOption={(params, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                {option.document} {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& input': {
                    padding: '0px',
                  },
                }}
                size="small"
                style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                ref={params.InputProps.ref}
                autoFocus
                placeholder={t('common:type.collaborator.name.badge') as string}
                InputProps={
                  {
                    ...params.inputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={searchIcon} alt={t('search.icon') as string} />
                      </InputAdornment>
                    ),
                    style: {
                      paddingRight: '10px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '10px',
                      fontSize: '13px',
                    },
                  } as InputProps
                }
              />
            )}
          />
        </div>
      </div>
      <div />
    </Box>
  );
}

function Costcenter() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const tableEl = useRef() as unknown as MutableRefObject<HTMLInputElement>;
  const { t } = useTranslation(['costcenter', 'common']);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderBy, setOrderBy] = useState('code');
  const [order, setOrder] = useState('asc' as 'asc' | 'desc');
  const [lastPage, setLastPage] = useState(-3);

  const [totalPages, setTotalPages] = useState(100);
  const [allData, setAllData] = useState([] as CostCenterResource[]);
  const [showClosed, setShowClosed] = useState(false);

  const currentResult = useCostCenterResourceQuery({
    email: keycloak?.tokenParsed?.email,
    bringClosed: showClosed,
    page: currentPage,
    orderBy,
    order,
  });

  const tableRef = tableEl.current;

  const scrollListener = useCallback(() => {
    if (tableRef !== undefined) {
      const bottom = tableRef.scrollHeight - tableRef.clientHeight;
      if (bottom === tableRef.scrollTop && currentPage < totalPages - 1) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [currentPage, totalPages, tableRef]);

  useLayoutEffect(() => {
    const tableRef2 = tableEl.current;
    if (tableRef2 !== undefined) {
      tableRef2.addEventListener('scroll', scrollListener);
      return () => {
        tableRef2.removeEventListener('scroll', scrollListener);
      };
    }
    return () => ({});
  }, [scrollListener]);

  const combined = useMemo(() => {
    if (currentResult.data !== undefined) {
      if (currentPage === lastPage) {
        setCurrentPage(0);
        setTotalPages(currentResult.data.totalPages);
        setAllData(() => [...(currentResult?.data?.content || [])]);
        return [...(currentResult?.data?.content || [])];
      }

      setTotalPages(currentResult.data.totalPages);
      setAllData((a) => [...a, ...(currentResult?.data?.content || [])]);
      setLastPage(currentPage);
      return [...allData, ...currentResult.data.content];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResult.data]);

  return (
    <div style={{ width: '100%' }}>
      <SearchBarsCostCenter showClosed={showClosed} setShowClosed={setShowClosed} />

      <div>
        <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: '#888888', marginBottom: '20px' }}>
          {t('my.cost.centers')}
        </Typography>
        {combined && (
          <TableContainer component={Paper} style={{ maxHeight: '60vh' }} ref={tableEl}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ color: '#4B4B4B', height: '40px', fontSize: '13px' }}>
                  <TableCell
                    style={{
                      width: '10%',
                      fontWeight: 'bold',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      fontSize: '13px',
                    }}
                    align="left"
                  >
                    <TableSortLabel
                      active={orderBy === 'code'}
                      direction={order}
                      onClick={() => {
                        setLastPage(0);
                        setCurrentPage(0);
                        if (orderBy === 'code') {
                          if (order === 'asc') {
                            setOrder('desc');
                          } else {
                            setOrder('asc');
                          }
                        } else {
                          setOrderBy('code');
                          setOrder('asc');
                        }
                      }}
                    >
                      {t('C.C.')}
                      {orderBy === 'code' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', paddingTop: '0px', paddingBottom: '0px', fontSize: '13px' }}>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={order}
                      onClick={() => {
                        setLastPage(0);
                        setCurrentPage(0);
                        if (orderBy === 'name') {
                          if (order === 'asc') {
                            setOrder('desc');
                          } else {
                            setOrder('asc');
                          }
                        } else {
                          setOrderBy('name');
                          setOrder('asc');
                        }
                      }}
                    >
                      {t('common:name')}
                      {orderBy === 'name' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combined?.map((costcenter: CostCenterResource, i) => (
                  <TableRow
                    key={costcenter.code}
                    sx={{
                      height: '40px',
                      border: '0px',
                      cursor: 'pointer',
                      backgroundColor: `${i % 2 === 0 ? '#FFFFFF' : '#FAFAFA'}`,
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    onClick={() => navigate(`${costcenter.code}`)}
                  >
                    <TableCell
                      sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}
                      align="left"
                    >
                      {costcenter.code}
                    </TableCell>
                    <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', border: '0px', fontSize: '13px' }}>
                      {costcenter.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}

export default Costcenter;
